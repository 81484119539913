import { createAction } from '../utils/actions';

export const BUILD = 'NAVIGATION/BUILD';

export const types = {
  BUILD,
};

export const build = createAction(BUILD);

export default {
  build,
};
