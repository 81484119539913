// use 'npm run g:a {name}' - to generate new action
import {createAction} from '../utils/actions';

export const FETCH = 'MICROSITES/FETCH';
export const FETCH_SUCCESS = 'MICROSITES/FETCH_SUCCESS';
export const FETCH_FAILURE = 'MICROSITES/FETCH_FAILURE';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
};

export const fetch = createAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export default {
  fetch,
  fetchSuccess,
  fetchFailure,
};
