/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAction } from './redux-create-reducer';

export type ActionCreator = (payload?: any) => IAction;

interface IActionModule {
  [key: string]: ActionCreator;
}

interface ITypesObject {
  [key: string]: string;
}

export const createAction = <P>(type: string) => (payload: P) => ({
  type,
  payload,
});

export const createEmptyAction = (type: string) => () => ({
  type,
  payload: {},
});

export const fakeAction = (payload?: any) => ({ type: 'fake', payload });
