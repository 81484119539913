import React, { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Table } from 'reactstrap';
import { IAlertHistoryItem } from '../../utils/api.d';
import { setAlertHistoryModal } from '../../actions/alertHistory';

import AjaxSpinner from '../../components/AjaxSpinner';
import styles from './AlertHistoryModal.module.scss';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getAlertHistory, IAlertHistoryState } from '../../reducers/alertHistory';

import moment from 'moment';
import _ from 'lodash';

const AlertHistoryModal = () => {
  const { isModalOpen, isLoading, alertHistory }: IAlertHistoryState = useSelector(getAlertHistory, shallowEqual);

  const dispatch = useDispatch();
  const closeModal = () => dispatch(setAlertHistoryModal(false));

  if (isLoading) return <AjaxSpinner />;

  if (!isModalOpen || alertHistory == null) return <div />;

  const alerts = _.orderBy(alertHistory.matches, ['date'], ['desc']);
  const dateTimeFormat = 'YYYY-MM-DD THH:mm:ss';

  return (
    <Modal isOpen={isModalOpen} className={styles['alert-history-modal']} centered>
      <ModalHeader toggle={closeModal}>
        <div>
          <strong>Email Alert History </strong>({alertHistory.email})
        </div>
        <div>
          {alertHistory.productName} (PID: {alertHistory.productId})
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <Table bordered>
            <thead>
              <tr>
                <td>
                  <b>Alert ID</b>
                </td>
                <td>
                  <b>Title</b>
                </td>
                <td>
                  <b>List ID</b>
                </td>
                <td>
                  <b>Language</b>
                </td>
                <td>
                  <b>Date</b>
                </td>
                <td>
                  <b>Delivery Status</b>
                </td>
                <td>
                  <b>Opened</b>
                </td>
                <td>
                  <b>Clicked</b>
                </td>
              </tr>
            </thead>
            <tbody>
              {alerts.map(function(alert: IAlertHistoryItem) {
                return (
                  <tr key={alert.id.toString()}>
                    <td>{alert.id}</td>
                    <td>{alert.title}</td>
                    <td>{alert.listId}</td>
                    <td>{alert.language.toUpperCase()}</td>
                    <td className={styles['alert-history-nowrap']}>{moment(alert.date).format(dateTimeFormat)}</td>
                    <td>{alert.deliveryStatus || '-'}</td>
                    <td className={styles['alert-history-nowrap']}>
                      {alert.lastOpenedDate !== null ? moment(alert.lastOpenedDate).format(dateTimeFormat) : '-'}
                    </td>
                    <td className={styles['alert-history-nowrap']}>
                      {alert.lastClickedDate !== null ? moment(alert.lastClickedDate).format(dateTimeFormat) : '-'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AlertHistoryModal;
