// use 'npm run g:s {name}' - to generate new saga, reducer and action (use --skip if action and/or reducer not needed)
import { put, call } from 'redux-saga/effects';
import { resetUser } from '../actions/bsuuser';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';

export function* fetcher() {
  return yield put(resetUser());
}

export default function* bsuuserPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.AddBSUUser, fetcher);
  }
}
