import { CognitoAuth } from 'amazon-cognito-auth-js';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { config as AWSConfig } from 'aws-sdk';
import { IAuthState } from '../reducers/auth';
import { IUser } from '../reducers/user';
import {
  REACT_APP_AWS_USERPOOLBASEURI,
  REACT_APP_AWS_USERPOOL,
  REACT_APP_CLIENT_ID,
  REACT_APP_AWS_TOKENSCOPES,
  REACT_APP_CALLBACK_URI,
  REACT_APP_AWS_REGION,
  SIGNOUT_URL,
} from '../env';

/**
 * @interface
 * @property [session] {IAuthState}
 * @property [user] {IUser}
 */
export interface ICognitoSession {
  session?: IAuthState;
  user?: IUser;
}

/**
 * @class
 * @name Auth
 */
export default class Auth {
  /**
   * @type {Auth}
   * @static
   * @private
   */
  private static instance: Auth;
  /**
   * @type {CognitoAuth}
   */
  auth: CognitoAuth;
  /**
   * @type {CognitoUserPool}
   */
  userPool: CognitoUserPool;
  /**
   * @type {CognitoUser | null}
   */
  user: CognitoUser | null;
  /**
   * Domain url
   * @type {appWebDomain}
   */
  appWebDomain: string;
  constructor() {
    AWSConfig.region = REACT_APP_AWS_REGION;

    this.appWebDomain = REACT_APP_AWS_USERPOOLBASEURI.replace('https://', '').replace('http://', '');

    this.auth = new CognitoAuth({
      UserPoolId: REACT_APP_AWS_USERPOOL,
      ClientId: REACT_APP_CLIENT_ID,
      AppWebDomain: this.appWebDomain,
      TokenScopesArray: [REACT_APP_AWS_TOKENSCOPES], //this.tokenScopes,
      RedirectUriSignIn: REACT_APP_CALLBACK_URI,
      RedirectUriSignOut: SIGNOUT_URL,
    });

    this.userPool = new CognitoUserPool({
      UserPoolId: REACT_APP_AWS_USERPOOL,
      ClientId: REACT_APP_CLIENT_ID,
    });

    this.user = this.userPool.getCurrentUser();
  }

  /**
   * Return current auth instance
   * @method
   * @static
   * @public
   * @return {Auth.instance}
   */
  public static getInstance() {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  /**
   * Parse cognito web response
   * @param href {string}
   * @return {Promise}
   */
  public parseCognitoWebResponse = (href: string): Promise<any> =>
    new Promise((onSuccess, reject) => {
      // userHandler will trigger the promise
      this.auth.userhandler = {
        onSuccess,
        onFailure: function(err: any) {
          reject(new Error('Failure parsing Cognito web response: ' + err));
        },
      };
      this.auth.parseCognitoWebResponse(href);
    });

  /**
   * Should return cognito session
   * @return {Promise<ICognitoSession | null>}
   */
  public getCognitoSession = (): Promise<ICognitoSession | null> =>
    new Promise((resolve, reject) => {
      if (this.user) {
        this.user.getSession((err: any, result: any) => {
          if (err || !result) {
            reject(new Error('Failure getting Cognito session: ' + err));
          }
          const data = result.idToken.payload;

          const entitlementString: string = data['custom:entitlements'];
          const entitlements = ((entitlementString && entitlementString) || '')
            .replace(/\[|\]/g, '')
            .split(',')
            .map(e => e.trim());

          const cognitoSessionObj: ICognitoSession = {
            session: {
              authenticated: true,
              tokens: {
                accessToken: result.accessToken.jwtToken,
                idToken: result.idToken.jwtToken, //,
                //refreshToken: result.refreshToken.token
              },
            },
            user: {
              id: data['custom:userid'],
              name: data['cognito:username'],
              wcUserId: data['custom:userid'],
              customerName: data['custom:customerName'],
              firstName: data['given_name'],
              lastName: data['family_name'],
              userTimeZone: data['user_timezone'],
              userHourFormat: data['custom:user_hourformat'],
              customerId: data['custom:customerId'],
              sessionId: data['custom:SessionId'],
              userLanguage: data['custom:user_language'],
              entitlements,
            },
          };

          resolve(cognitoSessionObj);
        });
      }
      resolve(null);
    });
}
