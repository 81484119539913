// use 'npm run g:s {name}' - to generate new saga, reducer and action (use --skip if action and/or reducer not needed)
import { call, put, take } from 'redux-saga/effects';
import {
  fetchSuccess,
  fetchFailure,
  FETCH_HOLIDAY_CALENDAR,
  FETCH_CALENDAR_DATA,
  fetchCalendarDataSuccess,
  fetchCalendarDataFailure,
  PUT_CALENDAR_DATA,
} from '../actions/holidayCalendar';
import { callApi } from '../utils/helpers';
import { getHolidayCalendar, getHolidays, putHolidays } from '../utils/api';
import { IAction } from '../utils/redux-create-reducer';
import { AxiosResponse } from 'axios';
import { TSaga, ICalendarData } from '../utils/api.d';

export default function* holidayCalendar() {
  while (true) {
    yield take(FETCH_HOLIDAY_CALENDAR);
    try {
      const response = yield call(callApi, getHolidayCalendar);
      yield put(fetchSuccess(response.data));
    } catch (e) {
      yield put(fetchFailure(e));
    }
  }
}

export function* getCalendarData(): TSaga<IAction & AxiosResponse<ICalendarData>> {
  while (true) {
    const { payload } = yield take(FETCH_CALENDAR_DATA);
    try {
      const response = yield call(callApi, getHolidays, payload);
      yield put(fetchCalendarDataSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchCalendarDataFailure(e));
    }
  }
}

export function* putCalendarData(): TSaga<IAction & AxiosResponse<ICalendarData>> {
  while (true) {
    const { payload } = yield take(PUT_CALENDAR_DATA);
    try {
      const response = yield call(callApi, putHolidays, payload);
      yield put(fetchCalendarDataSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchCalendarDataFailure(e));
    }
  }
}
