import createReducer from '../utils/redux-create-reducer';
import { BUILD } from '../actions/navigation';
import { N3AEntitlements, Routes } from '../constants';

export interface INavItem {
  nav: string;
  text: string;
  url: string;
  routes: string[];
  access: string;
  subNavElements?: INavItem[];
  useAppRouting: boolean;
}

export interface INavigation {
  menu: INavItem[];
}

export const initialState: INavigation = {
  menu: [
    {
      nav: '/',
      text: 'Home',
      url: Routes.Root,
      routes: [Routes.Root],
      access: N3AEntitlements.UnisonUser,
      useAppRouting: true,
    },
    {
      nav: 'webhosting',
      text: 'Webhosting',
      url: Routes.Webhosting,
      routes: [Routes.Webhosting, Routes.AddBSUUser],
      access: N3AEntitlements.UnisonUser,
      useAppRouting: true,
      subNavElements: [
        {
          nav: 'lookup',
          text: 'Subscriber Lookup',
          url: Routes.Webhosting,
          routes: [Routes.Webhosting],
          access: N3AEntitlements.UnisonUser,
          useAppRouting: true,
        },
        {
          nav: 'bsuUser',
          text: 'Add BSU User',
          url: Routes.AddBSUUser,
          routes: [Routes.AddBSUUser],
          access: N3AEntitlements.UnisonUser,
          useAppRouting: true,
        },
        {
          nav: 'holidayCalendar',
          text: 'Market Exchange Holiday Calendar',
          url: Routes.HolidayCalendar,
          routes: [Routes.HolidayCalendar],
          access: N3AEntitlements.UnisonAdmin,
          useAppRouting: true,
        },
      ],
    },
    {
      nav: 'microsites',
      text: 'Microsites',
      url: Routes.Microsites,
      routes: [Routes.Microsites],
      access: N3AEntitlements.UnisonUser,
      useAppRouting: true,
    },
  ],
};

export const getNavigation = (state: { navigation: INavigation }): INavigation => state.navigation;

export default createReducer(initialState, {
  [BUILD]: menu => ({
    menu,
  }),
});
