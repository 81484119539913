import { eventChannel } from 'redux-saga';
import { put, take } from 'redux-saga/effects';
import { build } from '../actions/navigation';
import { INavItem } from '../reducers/navigation';
import { push } from 'connected-react-router';

export interface IBuildEvent {
  navbar: INavItem[];
}

export interface IEvent<T> {
  action: string;
  data: T;
}

export default function* navigation() {
  const messageChannel = eventChannel(emitter => {
    window.addEventListener('message', emitter);
    return () => window.removeEventListener('message', emitter);
  });

  while (true) {
    try {
      const message: { data: IEvent<IBuildEvent | INavItem> } = yield take(messageChannel);
      const { action, data } = message.data;
      if (action == 'wc:weconvene:navigation-setup') {
        const value = data as IBuildEvent;
        yield put(build(value.navbar));
      }
      if (action == 'wc:weconvene:navigation') {
        const value = data as INavItem;
        yield put(push(value.url));
      }
    } catch {}
  }
}
