// use 'npm run g:s {name}' - to generate new saga, reducer and action (use --skip if action and/or reducer not needed)
import { call, put, take } from 'redux-saga/effects';
import { FETCH_MICROSITE_DETAILS, fetchSuccess, fetchFailure } from '../actions/micrositeDetails';
import { callApi } from '../utils/helpers';
import { getMicrositeDetails } from '../utils/api';

export default function* micrositeDetails() {
  while (true) {
    const { payload } = yield take(FETCH_MICROSITE_DETAILS);
    try {
      const response = yield call(callApi, getMicrositeDetails, payload);
      yield put(fetchSuccess(response.data));
    } catch (e) {
      yield put(fetchFailure(e));
    }
  }
}
