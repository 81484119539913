import React, { useEffect, useState } from 'react';
import { isBefore, parseISO } from 'date-fns';
import { formatInTimeZone } from '../../utils/helpers';
import { utcToZonedTime } from 'date-fns-tz';
import './styles.scss';
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCalendarData, getHolidayCalendar } from '../../reducers/holidayCalendar';
import { fetchCalendarData, putCalendarData } from '../../actions/holidayCalendar';
import { ICalendarDatum } from '../../utils/api.d';
import { getApplication, IApplication } from '../../reducers/application';

const HolidayCalendar = () => {
  const { holidayCalendar } = useSelector(getHolidayCalendar, shallowEqual);
  const { calendarData } = useSelector(getCalendarData, shallowEqual);
  const app: IApplication = useSelector(getApplication, shallowEqual);
  const dispatch = useDispatch();
  const [calendarsToShow, setCalendarsToShow] = useState([] as string[]);
  const [calendarDataToShow, setCalendarDataToShow] = useState([] as ICalendarDatum[]);
  const [toShow, setToShow] = useState(false);
  const [calendarName, setCalendarName] = useState('US/HOLIDAY');
  const [updatedCalendarName, setUpdatedCalendarName] = useState('');
  const [dropdownButtonOpen, setDropdownButtonOpen] = useState(false);
  const dropdownButtonToggle = () => setDropdownButtonOpen(prevState => !prevState);
  useEffect(() => {
    setCalendarsToShow(holidayCalendar.keys);
  }, [holidayCalendar]);
  useEffect(() => {
    calendarData && setCalendarDataToShow(calendarData.data);
  }, [calendarData]);
  const changeCalendar = (value: any) => {
    setCalendarName(value);
    setToShow(false);
    dispatch(fetchCalendarData(value));
  };
  useEffect(() => {
    if (calendarsToShow.includes('US/HOLIDAY')) {
      changeCalendar('US/HOLIDAY');
    } else {
      calendarsToShow.length && changeCalendar(calendarsToShow[0]);
    }
  }, [calendarsToShow]);
  const toggleHoliday = (value: boolean, index: number) => {
    const submitData = {
      key: calendarName,
      data: [
        {
          date: calendarDataToShow[index].date,
          isTrading: value,
        },
      ],
    };
    setUpdatedCalendarName(calendarDataToShow[index].description);
    setToShow(true);
    dispatch(putCalendarData(submitData));
  };

  const successMessage = !app.error && !app.isLoading && (
    <Alert isOpen={toShow} toggle={() => setToShow(false)}>
      {updatedCalendarName} equity market status has been updated
    </Alert>
  );

  return (
    <>
      {successMessage}
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Row>
              <Col sm={12}>
                <h5>Market Exchange Holiday Calendar</h5>
                <p>
                  Select a calendar to view upcoming holidays for that country. To override the holiday status for a
                  specific date toggle the market status switch to open.
                </p>
                <Dropdown isOpen={dropdownButtonOpen} toggle={dropdownButtonToggle} direction="down">
                  <DropdownToggle color="primary" caret>
                    Calendars
                  </DropdownToggle>
                  <DropdownMenu>
                    {(calendarsToShow || []).map(calendar => (
                      <DropdownItem key={calendar} onClick={() => changeCalendar(calendar)}>
                        <div>{calendar}</div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <p className={'calendar-name'}>{calendarName}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={'holidays-table'}>
          <Col sm={12}>
            <table>
              <thead>
                <tr>
                  <th>
                    <b>Date</b>
                  </th>
                  <th>
                    <b>Description</b>
                  </th>
                  <th>
                    <b>Equity Market Status</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(calendarDataToShow || []).map((datum: ICalendarDatum, index) => {
                  const disableToggle = isBefore(
                    utcToZonedTime(parseISO(datum.date + 'T13:00:00Z'), 'UTC'),
                    new Date(),
                  );
                  return (
                    <tr key={`row-${index}`}>
                      <td>{formatInTimeZone(parseISO(datum.date + 'T13:00:00Z'), 'EEE d MMM yyyy', 'UTC')}</td>
                      <td>{datum.description}</td>
                      <td>
                        <ButtonGroup>
                          <Button
                            color="primary"
                            disabled={disableToggle}
                            outline={!datum.isTrading}
                            onClick={() => toggleHoliday(true, index)}
                          >
                            Open
                          </Button>
                          <Button
                            color="primary"
                            disabled={disableToggle}
                            outline={datum.isTrading}
                            onClick={() => toggleHoliday(false, index)}
                          >
                            Closed
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HolidayCalendar;
