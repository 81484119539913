// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import { START_LOADING, STOP_LOADING, INIT, SET_APP_ERROR } from '../actions/application';

export interface IApplication {
  isLoading?: boolean;
  isFirstLoading?: boolean;
  error?: Error;
}

interface IState {
  application: IApplication;
}

export const initialState: IApplication = {
  isLoading: false,
  isFirstLoading: true,
  error: undefined,
};

export const getApplication = (state: IState): IApplication => state.application;

export default createReducer<IApplication>(initialState, {
  [START_LOADING]: () => ({ ...initialState, isLoading: true }),
  [STOP_LOADING]: () => ({ isLoading: false }),
  [INIT]: () => ({ isFirstLoading: false }),
  [SET_APP_ERROR]: error => ({ error, isLoading: false }),
});
