import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import styles from './VanityDomainModal.module.scss';

interface VanityDomainModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export function VanityDomainModal({ isOpen, closeModal }: VanityDomainModalProps) {
  const [vanityUrl, setVanityUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const fieldUpdated = (event: any) => setVanityUrl(event.target.value);
  const validate = () => {
    let errorMsg = '';
    if (!Boolean(/^[a-z0-9\-]+[!?\.](.*)\.([a-z0-9]+)*$/.test(vanityUrl))) {
      errorMsg = 'The value should match "subdomain.company.com" template';
    }
    setErrorMessage(errorMsg);
  };
  return (
    <Modal isOpen={isOpen} className={styles.modal} toggle={closeModal}>
      <ModalHeader toggle={closeModal} className={styles.modalHeader}>
        <strong>Vanity Domain Configuration</strong>
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <Row>Add vanity domain for this site.</Row>
        <Row>
          <div className={styles.fieldWrapper}>
            <input
              className="form-control float-right"
              type="text"
              placeholder="subdomain.company.com"
              onChange={fieldUpdated}
              style={{ width: '24em', margin: '1em 0 0' }}
            />
            <span className={styles.error}>{errorMessage}</span>
          </div>
          <Button id="vanityButtonId" color="primary" className={styles.vanityButton} onClick={validate}>
            Add
          </Button>
        </Row>
        <Row className={styles.subText}>
          The URL must be a subdomain, naked domains are not supported. Do not include the HTTP protocol or trailing
          slash.
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default VanityDomainModal;
