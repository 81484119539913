// use 'npm run g:a {name}' - to generate new action
import { createAction, createEmptyAction } from '../utils/actions';
import { IMicrositeDetails, ICalendarData } from '../utils/api.d';

export const FETCH_HOLIDAY_CALENDAR = 'HOLIDAY_CALENDAR/FETCH';
export const FETCH_HOLIDAY_CALENDAR_SUCCESS = 'HOLIDAY_CALENDAR/FETCH_SUCCESS';
export const FETCH_HOLIDAY_CALENDAR_FAILURE = 'HOLIDAY_CALENDAR/FETCH_FAILURE';
export const FETCH_CALENDAR_DATA = 'HOLIDAY_CALENDAR/FETCH_CALENDAR_DATA';
export const FETCH_CALENDAR_DATA_SUCCESS = 'HOLIDAY_CALENDAR/FETCH_CALENDAR_DATA_SUCCESS';
export const FETCH_CALENDAR_DATA_FAILURE = 'HOLIDAY_CALENDAR/FETCH_CALENDAR_DATA_FAILURE';
export const PUT_CALENDAR_DATA = 'HOLIDAY_CALENDAR/PUT_CALENDAR_DATA';

export const types = {
  FETCH_HOLIDAY_CALENDAR,
  FETCH_HOLIDAY_CALENDAR_SUCCESS,
  FETCH_HOLIDAY_CALENDAR_FAILURE,
  FETCH_CALENDAR_DATA,
  FETCH_CALENDAR_DATA_SUCCESS,
  FETCH_CALENDAR_DATA_FAILURE,
  PUT_CALENDAR_DATA,
};

export const fetch = createEmptyAction(FETCH_HOLIDAY_CALENDAR);
export const fetchSuccess = createAction<IMicrositeDetails>(FETCH_HOLIDAY_CALENDAR_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_HOLIDAY_CALENDAR_FAILURE);
export const fetchCalendarData = createAction<string>(FETCH_CALENDAR_DATA);
export const fetchCalendarDataSuccess = createAction<ICalendarData>(FETCH_CALENDAR_DATA_SUCCESS);
export const fetchCalendarDataFailure = createAction<Error>(FETCH_CALENDAR_DATA_FAILURE);
export const putCalendarData = createAction(PUT_CALENDAR_DATA);
