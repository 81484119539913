import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InputGroup, Input, Button, Label, Form, Table } from 'reactstrap';
import { ISubscriberSearchState } from '../../reducers/subscribers';
import { IAssociation } from '../../utils/api.d';

import { fetch, removeFromSuppressed } from '../../actions/subscribers';
import { fetchAlertHistory } from '../../actions/alertHistory';

import get from 'lodash/get';
import AjaxSpinner from '../../components/AjaxSpinner';
import AlertHistoryModal from '../../components/AlertHistoryModal';
import './styles.scss';

const Webhosting = () => {
  const dispatch = useDispatch();
  const subscriberSearchState = useSelector((state: any) => state.subscribers) as ISubscriberSearchState;

  const subscriptionStatusDisplayValues = {
    Active: 'Active',
    NotConfirmed: 'Unconfirmed',
    NotEngaged: 'Inactive',
  };

  const { search } = subscriberSearchState.searchResult;
  const isAnyMatches = get(subscriberSearchState, 'searchResult.matches.length', 0) > 0;
  const associations = get(subscriberSearchState, 'searchResult.matches[0].associations', [] as IAssociation[]);
  const suppression = get(subscriberSearchState, 'searchResult.matches[0].suppression', null);
  const subscriberId = get(subscriberSearchState, 'searchResult.matches[0].id');
  const subscriberEmail = get(subscriberSearchState, 'searchResult.matches[0].email');
  const RemoveFromSuppression = () => {
    const payload: any = {
      Email: search,
      SuppressionId: suppression.id,
    };
    dispatch(removeFromSuppressed(payload));
  };

  const formSearchResultInfo = () => {
    if (!isAnyMatches) {
      return <Label> {`${search} not found for any products`} </Label>;
    }
    if (associations != null && suppression == null) {
      return <Label> {`${search} for the following product(s)`} </Label>;
    }
    if (suppression != null) {
      return (
        <div>
          <Label> {`${search} was found on the blacklist.`} </Label>
          <span> </span>
          <a onClick={RemoveFromSuppression} href="#">
            Remove
          </a>
          <br />
          <Label>{`Activity details: ${suppression.detail}`}</Label>
        </div>
      );
    }
    return <div></div>;
  };

  const searchForEmail = (searchValue: any) => {
    searchValue.preventDefault();
    const inputElement = searchValue.target.elements['subscriberLookupInput'];
    dispatch(fetch(inputElement.value));
  };

  const showHistoryModal = (productId: number, subscriberId: number, emailAddress: string) => {
    const payload: any = {
      productId,
      subscriberId,
      emailAddress,
    };
    dispatch(fetchAlertHistory(payload));
  };

  return (
    <div id="WebhostingPage">
      <div className="pane">
        {subscriberSearchState.isLoading ? (
          <AjaxSpinner />
        ) : (
          <div>
            <h5>Subscriber Lookup</h5>
            <Form onSubmit={searchForEmail} id="subscriberLookup" className="subscriber-lookup-form">
              <InputGroup>
                <Input
                  required
                  type="email"
                  className="subscriber-lookup-input"
                  id="subscriberLookupInput"
                  name="subscriberLookupInput"
                  placeholder="Enter an email address"
                ></Input>
                <Button color="primary" className="float-left subscriber-lookup-button" type="submit">
                  Lookup
                </Button>
              </InputGroup>
            </Form>
          </div>
        )}
        <div className="search-result-block">
          {search != '' && <Label id="SearchResultInfo"> {formSearchResultInfo()} </Label>}
        </div>
        <div className="products-table-block">
          {search != '' && isAnyMatches && suppression == null && (
            <Table bordered>
              <thead>
                <tr>
                  <td>
                    <b>Product</b>
                  </td>
                  <td>
                    <b>Subscriber Status</b>
                  </td>
                  <td>
                    <b>Actions</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {isAnyMatches &&
                  associations.map(function(association: IAssociation) {
                    return (
                      <tr key={association.hubProductId.toString()}>
                        <td>{`${association.name} (PID ${association.hubProductId})`}</td>
                        <td>{subscriptionStatusDisplayValues[association.subscriptionStatus]}</td>
                        <td>
                          {association.hasAlertHistory &&
                          subscriptionStatusDisplayValues[association.subscriptionStatus] !==
                            subscriptionStatusDisplayValues.NotConfirmed ? (
                            <a
                              onClick={e => {
                                e.preventDefault();
                                showHistoryModal(association.hubProductId, subscriberId, subscriberEmail);
                              }}
                              href="#"
                            >
                              View Email History
                            </a>
                          ) : (
                            ''
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}

          <AlertHistoryModal />
        </div>
      </div>
    </div>
  );
};

export default Webhosting;
