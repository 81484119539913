import { call, put, take } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import { FETCH, fetchFailure, fetchSuccess } from '../actions/microsites';
import api from '../utils/api';
import { AxiosResponse } from 'axios';
import { IAction } from '../utils/redux-create-reducer';
import { IMicrositeResponse, TSaga } from '../utils/api.d';

export default function* microsites(): TSaga<IAction & AxiosResponse<IMicrositeResponse>> {
  while (true) {
    const { payload } = yield take(FETCH);
    try {
      const response = yield call(callApi, api.getSites, payload);
      yield put(fetchSuccess(response.data.sites));
    } catch (e) {
      console.error(e);
      yield put(fetchFailure(e));
    }
  }
}
