import React, { useState } from 'react';
import './styles.scss';
import { Button, Container, Tooltip } from 'reactstrap';
import { useSelector, shallowEqual } from 'react-redux';
import { getMicrositeDetails } from '../../reducers/micrositeDetails';
import { useHistory } from 'react-router-dom';
import VanityDomainModal from '../../components/VanityDomainModal';

const Microsites = () => {
  const { micrositeDetails, isLoading } = useSelector(getMicrositeDetails, shallowEqual);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showModule, setShowModule] = useState(false);
  const history = useHistory();
  const toggleModal = () => {
    setShowModule(!showModule);
  };
  micrositeDetails.n3a_entity = micrositeDetails.n3a_entity || {
    customer_name: 'n/a',
    customer_id: 'n/a',
    microsite_id: 'n/a',
    microsite_name: 'n/a',
  };

  const live = micrositeDetails.environments.filter(x => x.env_name.toLowerCase() == 'live')[0] || {
    env_name: 'Live',
    vanity_url: 'n/a',
    created: 0,
    created_by: 'n/a',
    internal_domain: '',
  };

  const staging = micrositeDetails.environments.filter(x => x.env_name.toLowerCase() == 'staging')[0] || {
    env_name: 'Staging',
    vanity_url: 'n/a',
    created: 0,
    created_by: 'n/a',
    internal_domain: '',
  };

  micrositeDetails.environments = [live, staging];

  return (
    <Container fluid className={'page-container'}>
      <div className={'sites-pane'}>
        <div className={'title-area'}>
          <div className={'header-title'}>{micrositeDetails.n3a_entity.customer_name}</div>
          <button className={'close-button'} onClick={() => history.go(-1)}>
            X
          </button>
        </div>
        {!isLoading && micrositeDetails.environments.length && (
          <>
            {micrositeDetails.environments[0].active && (
              <Button
                id="vanityButtonId"
                color="primary"
                className="float-left vanity-button"
                onClick={() => setShowModule(!showModule)}
              >
                Vanity domain config
              </Button>
            )}
            {!micrositeDetails.environments[0].active && (
              <>
                <Button
                  id="vanityButtonId"
                  color="secondary"
                  className="float-left vanity-button vanity-button-inactive"
                  onClick={e => e.preventDefault()}
                >
                  Vanity domain config
                </Button>
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpen}
                  target="vanityButtonId"
                  toggle={() => setTooltipOpen(!tooltipOpen)}
                  autohide={false}
                  key="vanityButtonIdTooltip"
                >
                  A vanity domain cannot be added until this site is deployed to the live environment.
                </Tooltip>
              </>
            )}
            <table className={'details'}>
              <tr>
                <td className={'bold-title'}>Customer ID</td>
                <td id={'space-left'}>{micrositeDetails.n3a_entity.customer_id}</td>
              </tr>
              <tr>
                <td className={'bold-title'}>Site ID</td>
                <td id={'space-left'}>{micrositeDetails.n3a_entity.microsite_id}</td>
              </tr>
              <tr>
                <td className={'bold-title'}>Microsite Name</td>
                <td id={'space-left'}>{micrositeDetails.n3a_entity.microsite_name}</td>
              </tr>
              <tr>
                <td className={'bold-title'}>Vanity URL(s)</td>
                <td id={'space-left'}>{micrositeDetails.environments[0].vanity_url}</td>
              </tr>
              <tr>
                <td className={'bold-title'}>Jenkins</td>
                <td id={'space-left'}>
                  <a
                    href={`https://jenkins.ops1.bld.wdc.dianum.io/job/sitebuilders_deployments/job/phx-site-${micrositeDetails.site_uid}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`https://jenkins.ops1.bld.wdc.dianum.io/job/sitebuilders_deployments/job/phx-site-${micrositeDetails.site_uid}`}
                  </a>
                </td>
              </tr>
              <tr>
                <td className={'bold-title'}>Github</td>
                <td id={'space-left'}>
                  <a
                    href={`https://git.dianum.io/DM-IR-SiteBuilder/phx-site-${micrositeDetails.site_uid}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`https://git.dianum.io/DM-IR-SiteBuilder/phx-site-${micrositeDetails.site_uid}`}
                  </a>
                </td>
              </tr>
              <tr>
                <td id={'space-up'} className={'bold-title'}>
                  Environments
                </td>
                <td id={'space-up-left'} className={'bold-capitalize'}>
                  Staging
                </td>
                <td id={'space-up-left'} className={'bold-capitalize'}>
                  Live
                </td>
              </tr>
              <tr className={'lined'}>
                <td id={'space-down'}>Internal domain</td>
                <td id={'space-down-left'}>
                  {micrositeDetails.environments[1].internal_domain ? (
                    <a
                      href={`https://${micrositeDetails.environments[1].internal_domain}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`https://${micrositeDetails.environments[1].internal_domain}`}
                    </a>
                  ) : (
                    'n/a'
                  )}
                  <p>
                    Created:&nbsp;
                    {micrositeDetails.environments[1].created == 0
                      ? 'n/a'
                      : new Date(micrositeDetails.environments[1].created * 1000).toLocaleString('en-US')}
                  </p>
                </td>
                <td id={'space-down-left'}>
                  {micrositeDetails.environments[0].internal_domain ? (
                    <a
                      href={`https://${micrositeDetails.environments[0].internal_domain}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`https://${micrositeDetails.environments[0].internal_domain}`}
                    </a>
                  ) : (
                    'n/a'
                  )}
                  <p>
                    Created:&nbsp;
                    {micrositeDetails.environments[0].created == 0
                      ? 'n/a'
                      : new Date(micrositeDetails.environments[0].created * 1000).toLocaleString('en-US')}
                  </p>
                </td>
              </tr>
            </table>
          </>
        )}
      </div>
      <VanityDomainModal isOpen={showModule} closeModal={toggleModal} />
    </Container>
  );
};

export default Microsites;
