import React from 'react';
import { fetch } from '../../actions/microsites';
import { IMicrositeItem } from '../../utils/api.d';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { CellClickedEvent, GridApi } from 'ag-grid-community';
import { Col, Row } from 'reactstrap';
import { Routes } from '../../constants';

export const onCellClicked = (micrositeData: any, callback: (route: string) => void) => {
  const route = Routes.MicrositeDetails.replace(':id', micrositeData.site_uid);
  callback(route);
};

class MicrositesTable extends React.Component<any, any> {
  gridApi: GridApi | null = null;

  componentDidMount() {
    this.props.getSites();
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (!prevProps.error && this.props.error) {
      this.setError();
    } else if (prevProps.sites.length == 0 && this.props.sites.length > 0) {
      this.loadRows(this.props.sites);
    }
  }

  handleQuickFilter = (event: any) => {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(event.target.value);
    }
  };

  normalizeSiteData(sites: IMicrositeItem[]) {
    const siteData: any = [];
    const siteLength = (sites && sites.length) || 0;
    for (let i = 0; i < siteLength; i++) {
      const site = sites[i];
      const siteEnvLength = (site && site.environments && site.environments.length) || 0;
      const site_uid = site['site_uid'];
      const n3a_info = site['n3a_entity'];
      for (let j = 0; j < siteEnvLength; j++) {
        const environment = site.environments[j];
        siteData.push({
          site_uid,
          ...environment,
          ...n3a_info,
        });
      }
    }

    return siteData;
  }

  setError() {
    if (this.gridApi) {
      this.gridApi.showNoRowsOverlay();
    }
  }

  loadRows(sites: any[]) {
    if (this.gridApi) {
      this.gridApi.setRowData(this.normalizeSiteData(sites));
    }
  }

  render() {
    const siteData = this.normalizeSiteData(this.props.sites);
    const defaultOptions = { sortable: true, resizable: true };
    const gridOptions = {
      columnDefs: [
        { headerName: 'Customer Name', field: 'customer_name', ...defaultOptions },
        { headerName: 'Customer ID', field: 'customer_id', ...defaultOptions },
        {
          headerName: 'Site ID (N3A)',
          field: 'site_uid',
          onCellClicked: (microsite: CellClickedEvent) => onCellClicked(microsite.data, this.props.historyPush),
          cellStyle: { color: '#459699', cursor: 'pointer' },
          ...defaultOptions,
        },
        { headerName: 'Microsite Name', field: 'microsite_name', ...defaultOptions },
        { headerName: 'Environment', field: 'env_name', ...defaultOptions },
        {
          headerName: 'Vanity URL',
          field: 'vanity_url',
          onCellClicked: (event: CellClickedEvent) => {
            if (event.data.vanity_url !== 'n/a') {
              window.open(`https://${event.data.vanity_url}`, '_blank');
            }
          },
          ...defaultOptions,
        },
        {
          headerName: 'Internal Domain',
          field: 'internal_domain',
          onCellClicked: (event: CellClickedEvent) => window.open(`https://${event.data.internal_domain}`, '_blank'),
          ...defaultOptions,
        },
      ],
    };
    return (
      <Col sm={12} className="ag-theme-material sites-table-module-container" style={{ height: '800px' }}>
        <Row>
          <Col sm={4}>
            <h5>Microsites</h5>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={'justify-content-end'}>
            <input
              className={'float-right'}
              type="text"
              placeholder="Search"
              onChange={this.handleQuickFilter}
              style={{ height: '25px' }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ height: '700px' }}>
            <AgGridReact
              rowData={siteData}
              onGridReady={params => {
                this.gridApi = params.api;
                this.gridApi.sizeColumnsToFit();
                this.gridApi.showLoadingOverlay();
              }}
              columnDefs={gridOptions.columnDefs}
              paginationAutoPageSize={true}
              pagination={true}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = (state: any) => ({
  error: state.microsites.error,
  sites: state.microsites.sites,
  isLoading: state.microsites.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSites: () => dispatch(fetch({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(MicrositesTable);
