import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SIGNOUT, setUserSession } from '../actions/auth';
import { setUser } from '../actions/user';
import { fetch as initialize } from '../actions/session';
import { RELOAD } from '../actions/session';
import { Routes } from '../constants';
import api from '../utils/api';
import Auth, { ICognitoSession } from '../utils/cognitoauth';
import { logError } from '../utils/helpers';
import { getModeStatus } from '../utils/browserHelpers';
import { SIGNIN_URL, REACT_APP_DISABLE_AUTH } from '../env';
import { getItem, setItem, removeItem } from '../utils/browserAPI';

/**
 * @typedef {Object} ITestRouter
 * @property {{location: {pathname: string}}} router
 */
interface ITestRouter {
  router: {
    location: {
      pathname: string;
    };
  };
}

const redirectBackKey = 'redirectBackKey';

function* setInitialUrl() {
  const key = yield call(getItem, redirectBackKey);
  if (!key || key === '/') {
    const url = location.href.replace(location.origin, '');
    yield call(setItem, redirectBackKey, url.indexOf('Callback') !== -1 ? '/' : url);
  }
}

const { getCognitoSession, parseCognitoWebResponse, auth } = Auth.getInstance();

/**
 * @function getLocation
 * @param {ITestRouter} state
 * Should return path name
 * @return {string}
 */
export function getLocation(state: ITestRouter): string {
  return state.router.location.pathname;
}

/**
 * @function Saga - getSession
 * Should fetch Cognito session or get fake session if authentication disabled
 * @return {Generator}
 */
function* getSession() {
  try {
    console.log({ REACT_APP_DISABLE_AUTH });

    if (REACT_APP_DISABLE_AUTH || getModeStatus()) {
      const s = yield call(api.getFakeSession);
      console.log({ s });
      return s;
    }
    return yield call(getCognitoSession);
  } catch (err) {
    yield call(logError, err);
    return null;
  }
}

/**
 * @function Saga - isCallbackRoute
 * Should check if the current route equal to callback route
 * @return {boolean}
 */
export function* isCallbackRoute() {
  const currentRoute = yield select(getLocation);
  return currentRoute.toLowerCase() === Routes.Callback.toLowerCase();
}
/**
 * @function Saga - redirectIfRequired
 * Should redirect to root route if the current one is callback route
 */
export function* redirectIfRequired() {
  const maybeCallback = yield* isCallbackRoute();
  if (maybeCallback) {
    const stateUrl = yield call(getItem, 'stateUrl');
    yield put(push(stateUrl || Routes.Root));
  }
}

/**
 * @function Saga - setSession
 * @param {ICognitoSession} cognitoSession
 * Should set user, user session and initialize application if user is authorized
 */
export function* setSession(cognitoSession: ICognitoSession) {
  if (cognitoSession.session) {
    yield put(setUserSession((cognitoSession && cognitoSession.session && cognitoSession.session.tokens) || {}));
    yield call(
      setItem,
      'token',
      cognitoSession.session && cognitoSession.session.tokens && cognitoSession.session.tokens.idToken,
    );
    yield put(setUser((cognitoSession && cognitoSession.user) || {}));
    yield put(initialize());

    yield* redirectIfRequired();
  }
}

export const redirectTo = (url: string) => {
  window.location.replace(url);
};

/**
 * @function Saga - signout
 * Should log out user
 */
export function* signout() {
  if (auth.isUserSignedIn()) {
    yield call(auth.signOut.bind(auth));
  } else {
    redirectTo(SIGNIN_URL);
  }
}

/**
 * @function reloadPage
 * Should reload page
 */
export const reloadPage = () => {
  window.location.reload();
};

let firstSession = true;
/**
 * @function Saga - authSaga
 * Should check session and either set it or redirect to login page
 */
export default function* authSaga() {
  try {
    const maybeCallback = yield* isCallbackRoute();
    let session = yield* getSession();
    if (maybeCallback && !session) {
      yield call(parseCognitoWebResponse, window.location.href);
      session = yield* getSession();
    }

    if (firstSession && maybeCallback && session) {
      firstSession = false;
      const redirectURL = yield call(getItem, redirectBackKey);
      yield call(removeItem, redirectBackKey);
      yield call(redirectTo, (redirectURL !== Routes.Logout && redirectURL) || Routes.Root);
    }

    if (session) {
      yield* setSession(session);
      yield takeLatest(SIGNOUT, signout);
      yield takeLatest(RELOAD, reloadPage);
    } else {
      yield call(setInitialUrl);
      yield call(redirectTo, SIGNIN_URL);
    }
  } catch (err) {
    yield call(logError, err);
  }
}
