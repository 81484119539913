import {
  REACT_APP_DASHBOARD_API_BASE_URL,
  REACT_APP_SESSION_API_BASE_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_ENV,
  SIGNIN_URL,
} from '../env';
import { fetch, getItem, removeItem } from './browserAPI';
import store from '../store';
import get from 'lodash/get';
import fakeSession from './cognitoSession.json';
import axios from 'axios';

import { IKeepAlive, InitializeData, ILogout, IBSUUser } from './api.d';

export enum HTTP {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}

export enum urls {
  validateToken = '/ValidateToken',
  keepAlive = '/keepAlive',
  initialize = '/initialize',
  logout = '/logout',
  subscribers = '/subscribers',
  products = '/products',
  microsites = '/microsites',
  bsuuser = '/bsuuser',
  holidayCalendar = '/holidayCalendar/holidayKeys',
  holidays = '/holidayCalendar/holidays',
}

export const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  withCredentials: REACT_APP_ENV !== 'local',
});
api.interceptors.request.use(
  config => {
    const token = getItem('token');
    if (token && token.length > 10) {
      if (config.headers) config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      removeItem('token');
      location.href = SIGNIN_URL;
    }

    return Promise.reject(error);
  },
);

export const makeHTTPRequest = async <T>(baseUrl: string, url: string, method: HTTP, data?: any): Promise<T> => {
  const headers = new Headers();
  const state = store.getState();
  const token = get(state, 'auth.tokens.idToken');
  let encodedJwt = get(state, 'session.token');
  const sessionString = getItem('session');
  try {
    const session = JSON.parse(`${sessionString}`);
    if (session.token) {
      encodedJwt = session.token;
    }
  } catch {}

  headers.append('Authorization', 'Bearer ' + token);
  headers.append('Content-Type', 'application/json');
  headers.append('encodedJwt', encodedJwt);
  headers.append('tzOffset', new Date().getTimezoneOffset().toString());
  const maybeData = data && (method === HTTP.post || method === HTTP.put) ? { body: JSON.stringify(data) } : {};
  const maybeGetParams =
    data && (method === HTTP.get || method === HTTP.delete)
      ? '?' +
        Object.keys(data)
          .filter(key => Boolean(data[key]))
          .map(key => `${key}=${data[key]}`)
          .join('&')
      : '';

  try {
    const response = await fetch(baseUrl + url + maybeGetParams, { ...maybeData, headers, method });
    const result = await response.json();
    if (response.status > 400) {
      throw response;
    }
    return result;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

const buildHttp = (bu: string) => <T>(u: string, m: HTTP, d?: any) => makeHTTPRequest<T>(bu, u, m, d);

const makeRequest = buildHttp(REACT_APP_DASHBOARD_API_BASE_URL);

export const postValidateToken = (token: string) => makeRequest(urls.validateToken, HTTP.post, { token });

export const getInitialize = () => {
  return makeRequest<InitializeData>(urls.initialize, HTTP.get);
};

export const putKeepAlive = (token: string) =>
  buildHttp(REACT_APP_SESSION_API_BASE_URL)<IKeepAlive>(urls.keepAlive, HTTP.put, { encodedJwt: token });

export const deleteLogout = () => buildHttp(REACT_APP_DASHBOARD_API_BASE_URL)<ILogout>(urls.logout, HTTP.get);

export const getFakeSession = () => Promise.resolve(fakeSession);

export const getSubscriberInfo = (email: string) => api.get(`${urls.subscribers}/${email}`);

export const removeSubscriberFromSuppressed = (suppressionId: number) =>
  api.post(`${urls.subscribers}/remove-from-suppressed?suppressionId=${suppressionId}`);

export const postBSUUser = (data: IBSUUser) => api.post(urls.bsuuser, data);

export const getAlertHistory = ({
  productId,
  subscriberId,
  emailAddress,
}: {
  productId: number;
  subscriberId: number;
  emailAddress: string;
}) => api.get(`${urls.products}/${productId}/AlertHistory?subscriberId=${subscriberId}&emailAddress=${emailAddress}`);

export const getSites = () => api.get(urls.microsites);

export const getMicrositeDetails = (uid: string) => api.get(`${urls.microsites}/${uid}`);

export const getHolidayCalendar = () => api.get(encodeURI(urls.holidayCalendar));

export const getHolidays = (key: string) => api.get(`${urls.holidays}?Key='${key}'`);

export const putHolidays = (data: any) => api.put(urls.holidays, data);

export default {
  urls,
  makeRequest,
  getInitialize,
  postValidateToken,
  putKeepAlive,
  deleteLogout,
  getFakeSession,
  getSites,
  getSubscriberInfo,
  removeSubscriberFromSuppressed,
  getAlertHistory,
  getMicrositeDetails,
  postBSUUser,
  getHolidayCalendar,
  getHolidays,
  putHolidays,
};
