import { call, take, put } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import {
  FETCH,
  REMOVE_FROM_SUPPRESSED,
  fetchSuccess,
  fetchFailure,
  fetch,
  removeFromSuppressedSuccess,
  removeFromSuppressedFailure,
} from '../actions/subscribers';
import api from '../utils/api';
import { AxiosResponse } from 'axios';
import { IAction } from '../utils/redux-create-reducer';
import { ISubscriberSearchResult, TSaga } from '../utils/api.d';

export default function* subscriber(): TSaga<IAction & AxiosResponse<ISubscriberSearchResult>> {
  while (true) {
    const { payload } = yield take(FETCH);
    try {
      const response = yield call(callApi, api.getSubscriberInfo, payload);
      yield put(fetchSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchFailure(e));
    }
  }
}

export function* removeSubscriberFromSuppressed(): TSaga<IAction & AxiosResponse<any>> {
  while (true) {
    const { payload } = yield take(REMOVE_FROM_SUPPRESSED);
    try {
      const response = yield call(callApi, api.removeSubscriberFromSuppressed, payload.SuppressionId);
      yield put(removeFromSuppressedSuccess(response));
      yield put(fetch(payload.Email));
    } catch (e) {
      console.error(e);
      yield put(removeFromSuppressedFailure(e));
    }
  }
}
