import { all } from 'redux-saga/effects';

// inject import
import auth from './auth';
// import initialize from './initialize';
// import keepAlive from './keepAlive';
import navigation from './navigation';
import subscriber, { removeSubscriberFromSuppressed } from './subscribers';
import alertHistory from './alertHistory';
import microsites from './microsites';
import micrositeDetailsPage from './micrositeDetailsPage';
import micrositeDetails from './micrositeDetails';
import holidayCalendar, { getCalendarData, putCalendarData } from './holidayCalendar';
import holidayCalendarPage from './holidayCalendarPage';
import addBSUUser from './bsuuser';
import bsuuserPage from './bsuuserPage';

const allSagas = [
  // inject usage
  //initialize,
  auth,
  // keepAlive,
  microsites,
  navigation,
  subscriber,
  removeSubscriberFromSuppressed,
  alertHistory,
  micrositeDetailsPage,
  micrositeDetails,
  addBSUUser,
  bsuuserPage,
  holidayCalendar,
  holidayCalendarPage,
  getCalendarData,
  putCalendarData,
];

export default function* appSagas() {
  yield all(allSagas.map(s => s()));
}
