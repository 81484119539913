import { createAction, createEmptyAction } from '../utils/actions';
export const REDIRECT_TO_LOGIN = 'AUTH/REDIRECT_TO_LOGIN';
export const SET_USER_SESSION = 'AUTH/SET_USER_SESSION';
export const REDIRECT_FROM_N3A = 'AUTH/REDIRECT_FROM_N3A';
export const SIGNOUT = 'AUTH/SIGNOUT';

export const types = {
  SET_USER_SESSION,
  REDIRECT_TO_LOGIN,
  REDIRECT_FROM_N3A,
  SIGNOUT,
};

export const redirectToLogin = createEmptyAction(REDIRECT_TO_LOGIN);
export const redirectFromN3A = createAction(REDIRECT_FROM_N3A);
export const setUserSession = createAction(SET_USER_SESSION);
export const signout = createEmptyAction(SIGNOUT);

export default {
  redirectToLogin,
  setUserSession,
  redirectFromN3A,
  signout,
};
