import { createAction, createEmptyAction } from '../utils/actions';
export const ADD_USER = 'USER/ADD';
export const ADD_USER_SUCCESS = 'USER/ADD_SUCCESS';
export const ADD_USER_FAILURE = 'USER/ADD_FAILURE';
export const RESET_USER = 'USER/RESET';

export const types = {
  ADD_USER,
};

export const addBSUUser = createAction(ADD_USER);
export const addBSUUserSuccess = createAction(ADD_USER_SUCCESS);
export const addBSUUserFailure = createAction<Error>(ADD_USER_FAILURE);
export const resetUser = createEmptyAction(RESET_USER);

export default {
  addBSUUser,
};
