import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Alert, Button, Col, Container, Form, Input, InputGroup, Row } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addBSUUser } from '../../actions/user';
import { getBSUUser } from '../../reducers/bsuuser';

const AddBSUUser = () => {
  const { bsuuser } = useSelector(getBSUUser, shallowEqual);
  const dispatch = useDispatch();
  const [toShow, setToShow] = useState(false);
  useEffect(() => {
    setToShow(Boolean(bsuuser && bsuuser.newUserID));
  }, [bsuuser]);
  const addUser = (value: any) => {
    value.preventDefault();
    const submitData = {
      FirstName: value.target.elements['firstNameInput'].value,
      LastName: value.target.elements['lastNameInput'].value,
      Email: value.target.elements['emailInput'].value,
      UserID: value.target.elements['userIdInput'].value,
    };
    dispatch(addBSUUser(submitData));
  };

  return (
    <>
      <Alert isOpen={toShow} toggle={() => setToShow(false)}>
        The user has been created.
      </Alert>
      <Container fluid className={'page-container'}>
        <Row className={'sites-pane'}>
          <Col sm={12} className="ag-theme-material sites-table-module-container" style={{ height: '800px' }}>
            <Row>
              <Col sm={4}>
                <h5>Add BSU User</h5>
                <p>Complete the below form to add a new user to the BSU.</p>
              </Col>
            </Row>
            <Form onSubmit={addUser} id="addUser" className="add-user-form">
              <InputGroup className={'rows-alignment'}>
                <Row className={'margin-bottom'}>
                  <Col sm={6} className={'justify-content-end'}>
                    <Input required id="firstNameInput" name="firstNameInput" placeholder="First name"></Input>
                  </Col>
                  <Col sm={6} className={'justify-content-end'}>
                    <Input required id="lastNameInput" name="lastNameInput" placeholder="Last name"></Input>
                  </Col>
                </Row>
                <Row className={'margin-bottom'}>
                  <Col sm={6} className={'justify-content-end'}>
                    <Input
                      required
                      type="text"
                      id="emailInput"
                      name="emailInput"
                      placeholder="Email address (ex. user@example.com)"
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                    ></Input>
                  </Col>
                  <Col sm={6} className={'justify-content-end'}>
                    <Input required id="userIdInput" name="userIdInput" placeholder="User ID (ex. pqXXXXX)"></Input>
                  </Col>
                </Row>
              </InputGroup>
            </Form>
            <Row>
              <Col sm={12} style={{ height: '700px' }}>
                <Button form="addUser" type="submit" color="primary" className="float-left add-user-button">
                  Add
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddBSUUser;
