// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import { IHolidayCalendar, ICalendarData } from '../utils/api.d';
import {
  FETCH_HOLIDAY_CALENDAR,
  FETCH_HOLIDAY_CALENDAR_FAILURE,
  FETCH_HOLIDAY_CALENDAR_SUCCESS,
  FETCH_CALENDAR_DATA,
  FETCH_CALENDAR_DATA_FAILURE,
  FETCH_CALENDAR_DATA_SUCCESS,
  PUT_CALENDAR_DATA,
} from '../actions/holidayCalendar';
import { deepStateUpdate } from '../utils/helpers';

export interface CalendarDataState {
  isLoading: boolean;
  error?: Error | null;
  calendarData: ICalendarData;
}

export interface IHolidayCalendarState {
  isLoading: boolean;
  error?: Error | null;
  holidayCalendar: IHolidayCalendar;
  calendarData: CalendarDataState;
}

interface IState {
  holidayCalendar: IHolidayCalendarState;
}

export const initialState: IHolidayCalendarState = {
  isLoading: false,
  holidayCalendar: {
    keys: [],
  },
  calendarData: {
    isLoading: false,
    error: undefined,
    calendarData: {
      key: '',
      data: [],
    },
  },
};

export const getHolidayCalendar = (state: IState): IHolidayCalendarState => state.holidayCalendar;
export const getCalendarData = (state: IState): CalendarDataState => state.holidayCalendar.calendarData;

const deepUpdate = deepStateUpdate('calendarData');

export default createReducer(initialState, {
  [FETCH_HOLIDAY_CALENDAR]: () => ({ isLoading: true }),
  [FETCH_HOLIDAY_CALENDAR_SUCCESS]: (holidayCalendar: IHolidayCalendar) => ({
    holidayCalendar,
    isLoading: false,
  }),
  [FETCH_HOLIDAY_CALENDAR_FAILURE]: error => ({ error, isLoading: false }),
  [FETCH_CALENDAR_DATA]: (_, state) =>
    deepUpdate(
      {
        isLoading: true,
        calendarData: undefined,
        error: undefined,
      },
      state,
    ),
  [FETCH_CALENDAR_DATA_SUCCESS]: (calendarData: ICalendarData, state) =>
    deepUpdate({ calendarData, isLoading: false }, state),
  [FETCH_CALENDAR_DATA_FAILURE]: (error, state) => deepUpdate({ error, isLoading: false }, state),
  [PUT_CALENDAR_DATA]: () => ({ isLoading: true }),
});
