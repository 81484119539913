import createReducer from '../utils/redux-create-reducer';
import { IEntitlement } from '../utils/api.d';
import { FETCH_SESSION, SET_SESSION, FETCH_SESSION_FAILURE, SET_USER_ACTIVITY } from '../actions/session';
import get from 'lodash/get';

export enum SessionStatuses {
  Active = 'active',
  NotFound = 'notFound',
  Expired = 'expired',
}

export interface ISession {
  isLoading?: boolean;
  token?: string;
  entitlements: IEntitlement[];
  isUserActive: boolean;
  isSessionIssue: boolean;
  sessionStatus: SessionStatuses;
  error?: Error;
}

interface IState {
  session: ISession;
}

export const initialState: ISession = {
  isLoading: false,
  sessionStatus: SessionStatuses.Active,
  isUserActive: true,
  isSessionIssue: false,
  entitlements: [],
};

export const getSession = (state: IState): ISession => state.session;

export const makeSessionStatus = (error: Error) => {
  const status = get(error, 'status');
  let sessionStatus;
  switch (Number(status)) {
    case 404:
    case 428:
    case 403:
    case 400:
      // Adding all 4xx with Expires Session.
      sessionStatus = SessionStatuses.Expired;
      break;

    default:
      sessionStatus = SessionStatuses.Active;
      break;
  }
  const isSessionIssue = sessionStatus !== SessionStatuses.Active;
  return { sessionStatus, isSessionIssue };
};

export default createReducer(initialState, {
  [FETCH_SESSION]: () => ({ ...initialState, isLoading: true }),
  [SET_SESSION]: data => ({ ...data, isLoading: false, sessionStatus: SessionStatuses.Active }),
  [FETCH_SESSION_FAILURE]: error => ({ error, isLoading: false, ...makeSessionStatus(error) }),
  [SET_USER_ACTIVITY]: isUserActive => ({ isUserActive }),
});
