export enum Routes {
  Root = '/',
  Callback = '/callback',
  Logout = '/logout',
  Webhosting = '/webhosting',
  Microsites = '/microsites',
  MicrositeDetails = '/microsite-details/:id',
  AddBSUUser = '/add-bsu-user',
  HolidayCalendar = '/market-exchange-holiday-calendar',
}

export enum N3AEntitlements {
  UnisonUser = 'Unison.Admin.Access',
  UnisonAdmin = 'Unison.Admin.Administrator',
}
