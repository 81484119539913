import { call, take, put } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import {
  FETCH_ALERT_HISTORY,
  fetchAlertHistorySuccess,
  fetchAlertHistoryFailure,
  setAlertHistoryModal,
} from '../actions/alertHistory';
import api from '../utils/api';
import { AxiosResponse } from 'axios';
import { IAction } from '../utils/redux-create-reducer';
import { TSaga, IAlertHistory } from '../utils/api.d';

export default function* alertHistory(): TSaga<IAction & AxiosResponse<IAlertHistory>> {
  while (true) {
    const { payload } = yield take(FETCH_ALERT_HISTORY);
    try {
      const response = yield call(callApi, api.getAlertHistory, payload);
      yield put(fetchAlertHistorySuccess(response.data));
      yield put(setAlertHistoryModal(true));
    } catch (e) {
      console.error(e);
      yield put(fetchAlertHistoryFailure(e));
    }
  }
}