// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  REMOVE_FROM_SUPPRESSED,
  REMOVE_FROM_SUPPRESSED_FAILURE,
  REMOVE_FROM_SUPPRESSED_SUCCESS,
} from '../actions/subscribers';
import { ISubscriberSearchResult, IMatching } from '../utils/api.d';

export interface ISubscriberSearchState {
  isLoading?: boolean;
  searchResult: ISubscriberSearchResult;
  error?: Error;
}

export const initialState: ISubscriberSearchState = {
  isLoading: false,
  searchResult: { search: '', matches: [] as IMatching[] } as ISubscriberSearchResult,
  error: undefined,
};

export default createReducer<ISubscriberSearchState>(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: searchResult => ({ searchResult, isLoading: false }),
  [FETCH_FAILURE]: error => ({ ...initialState, error, isLoading: false }),

  [REMOVE_FROM_SUPPRESSED]: () => ({ isLoading: true }),
  [REMOVE_FROM_SUPPRESSED_FAILURE]: error => ({ ...initialState, error, isLoading: false }),
  [REMOVE_FROM_SUPPRESSED_SUCCESS]: () => ({ isLoading: false }),
});
