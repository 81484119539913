// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH_MICROSITE_DETAILS,
  FETCH_MICROSITE_DETAILS_FAILURE,
  FETCH_MICROSITE_DETAILS_SUCCESS,
} from '../actions/micrositeDetails';
import { IMicrositeDetails } from '../utils/api.d';

export interface IMicrositeDetailsState {
  isLoading: boolean;
  error?: Error | null;
  micrositeDetails: IMicrositeDetails;
}

interface IState {
  micrositeDetails: IMicrositeDetailsState;
}

export const initialState: IMicrositeDetailsState = {
  isLoading: false,
  micrositeDetails: {
    site_uid: '',
    environments: [],
    n3a_entity: {
      customer_id: 0,
      customer_name: '',
      microsite_description: null,
      microsite_id: 0,
      microsite_name: '',
    },
  },
};

export const getMicrositeDetails = (state: IState): IMicrositeDetailsState => state.micrositeDetails;

export default createReducer(initialState, {
  [FETCH_MICROSITE_DETAILS]: () => ({ isLoading: true }),
  [FETCH_MICROSITE_DETAILS_SUCCESS]: (micrositeDetails: IMicrositeDetails) => ({
    micrositeDetails,
    isLoading: false,
  }),
  [FETCH_MICROSITE_DETAILS_FAILURE]: error => ({ error, isLoading: false }),
});
