// use 'npm run g:s {name}' - to generate new saga, reducer and action (use --skip if action and/or reducer not needed)
import { put, take, call } from 'redux-saga/effects';
import { FETCH_MICROSITE_DETAILS_SUCCESS, FETCH_MICROSITE_DETAILS_FAILURE, fetch } from '../actions/micrositeDetails';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';

export function* fetcher(match: { params: Record<string, string> }) {
  yield put(fetch(match.params.id));
  return yield take([FETCH_MICROSITE_DETAILS_SUCCESS, FETCH_MICROSITE_DETAILS_FAILURE]);
}

export default function* micrositeDetails() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.MicrositeDetails, fetcher);
  }
}
