import createReducer from '../utils/redux-create-reducer';
import { ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAILURE, RESET_USER } from '../actions/bsuuser';

export interface IBSUUserState {
  isLoading?: boolean;
  error?: Error | null;
  bsuuser: {
    newUserID: string;
  };
}

interface IState {
  bsuuser: IBSUUserState;
}

export const initialState: IBSUUserState = {
  isLoading: false,
  bsuuser: {
    newUserID: '',
  },
};

export const getBSUUser = (state: IState): IBSUUserState => state.bsuuser;

export default createReducer(initialState, {
  [ADD_USER]: () => ({ bsuuser: { newUserID: '' }, isLoading: true }),
  [ADD_USER_SUCCESS]: data => ({ bsuuser: data, isLoading: false }),
  [ADD_USER_FAILURE]: error => ({ ...initialState, error }),
  [RESET_USER]: () => ({ bsuuser: { newUserID: '' } }),
});
