// use 'npm run g:com {name}' - to generate new component with react hooks
import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { signout } from '../../actions/auth';
import { getSession, ISession, SessionStatuses } from '../../reducers/session';
import styles from './KeepAliveModal.module.scss';

export const expiredMessage = 'Your session has ended and you will need to re-authenticate';

const KeepAliveModal = () => {
  const session: ISession = useSelector(getSession, shallowEqual);
  const dispatch = useDispatch();
  const isExpired = session.sessionStatus === SessionStatuses.Expired;
  const close = () => {
    if (isExpired) {
      dispatch(signout());
    }
  };

  return (
    <Modal isOpen={session.isSessionIssue} className={styles.modal}>
      <ModalBody>{expiredMessage}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={close}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default KeepAliveModal;
