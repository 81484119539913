// use only for debug selectors
import application from './application';
import auth from './auth';
import session from './session';
import user from './user';
import bsuuser from './bsuuser';

export default {
  application,
  auth,
  session,
  user,
  bsuuser,
};
