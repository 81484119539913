// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH_ALERT_HISTORY = 'FETCH_ALERT_HISTORY';
export const FETCH_ALERT_HISTORY_SUCCESS = 'FETCH_ALERT_HISTORY/SUCCESS';
export const FETCH_ALERT_HISTORY_FAILURE = 'FETCH_ALERT_HISTORY/FAILURE';
export const ALERT_HISTORY_SET_MODAL = 'ALERT_HISTORY/SET_MODAL';

export const types = {
  FETCH_ALERT_HISTORY,
  FETCH_ALERT_HISTORY_SUCCESS,
  FETCH_ALERT_HISTORY_FAILURE,
  ALERT_HISTORY_SET_MODAL,
};

export const fetchAlertHistory = createAction(FETCH_ALERT_HISTORY);
export const fetchAlertHistorySuccess = createAction(FETCH_ALERT_HISTORY_SUCCESS);
export const fetchAlertHistoryFailure = createAction<Error>(FETCH_ALERT_HISTORY_FAILURE);
export const setAlertHistoryModal = createAction(ALERT_HISTORY_SET_MODAL);

export default {
  fetchAlertHistory,
  fetchAlertHistorySuccess,
  fetchAlertHistoryFailure,
  setAlertHistoryModal,
};
