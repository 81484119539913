import { IAction } from '../utils/redux-create-reducer';
import { AxiosResponse } from 'axios';
import { call, put, take } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import { IBSUUser, TSaga } from '../utils/api.d';
import api from '../utils/api';
import { ADD_USER, addBSUUserFailure, addBSUUserSuccess } from '../actions/bsuuser';

export default function* addBSUUser(): TSaga<IAction & AxiosResponse<IBSUUser>> {
  while (true) {
    const { payload } = yield take(ADD_USER);
    try {
      const response = yield call(callApi, api.postBSUUser, payload);
      yield put(addBSUUserSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(addBSUUserFailure(e));
    }
  }
}
