import createReducer from '../utils/redux-create-reducer';
import { SET_USER, DROP_USER, SWITCH_CUSTOMER } from '../actions/user';

export interface IUser {
  id: string;
  name: string;
  customerName: string;
  firstName: string;
  lastName: string;
  userTimeZone: string;
  userHourFormat: string;
  customerId: string;
  sessionId: string;
  wcUserId: string;
  userLanguage: string;
  entitlements: string[];
}

export const initialState: IUser = {
  id: '',
  name: '',
  customerName: '',
  firstName: '',
  lastName: '',
  userTimeZone: '',
  entitlements: [],
  userHourFormat: '',
  customerId: '',
  wcUserId: '',
  sessionId: '',
  userLanguage: '',
};

export const getUser = (state: { user: IUser }): IUser => state.user;

export default createReducer(initialState, {
  [SET_USER]: (user, state: IUser) => ({
    ...state,
    ...user,
    authenticated: true,
  }),
  [SWITCH_CUSTOMER]: customer => ({
    ...customer,
  }),
  [DROP_USER]: () => initialState,
});
