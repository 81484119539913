// use 'npm run g:a {name}' - to generate new action
import { createAction } from '../utils/actions';
import { IMicrositeDetails } from '../utils/api.d';

export const FETCH_MICROSITE_DETAILS = 'MICROSITE_DETAILS/FETCH';
export const FETCH_MICROSITE_DETAILS_SUCCESS = 'MICROSITE_DETAILS/FETCH_SUCCESS';
export const FETCH_MICROSITE_DETAILS_FAILURE = 'MICROSITE_DETAILS/FETCH_FAILURE';

export const types = {
  FETCH_MICROSITE_DETAILS,
  FETCH_MICROSITE_DETAILS_SUCCESS,
  FETCH_MICROSITE_DETAILS_FAILURE,
};

export const fetch = createAction(FETCH_MICROSITE_DETAILS);
export const fetchSuccess = createAction<IMicrositeDetails>(FETCH_MICROSITE_DETAILS_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_MICROSITE_DETAILS_FAILURE);
