// use 'npm run g:com {name}' - to generate new component with react hooks
import React from 'react';
import styles from './AjaxSpinner.module.scss';
import { Spinner } from 'reactstrap';

const color = 'secondary';

const AjaxSpinner = () => {
  return (
    <div className={styles.ajaxSpinner}>
      <div className={styles.spinnerWrap}>
        <Spinner className={styles.spinner} color={color} />
        <p className={`text-${color} ${styles.text}`}>Gathering your data ...</p>
      </div>
      <div className={styles.background} />
    </div>
  );
};

export default AjaxSpinner;
