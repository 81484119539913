import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Root from './pages/Root';
import Webhosting from './pages/Webhosting';
import { LayoutWrapper } from './containers/Layout';
import { Routes } from './constants';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store';
import Callback from './pages/Callback';
import Logout from './pages/Logout';
import Microsites from './pages/Microsites';
import MicrositeDetails from './pages/MicrositeDetails';
import AddBSUUser from './pages/AddBSUUser';
import HolidayCalendar from './pages/HolidayCalendar';

export const NoMatchPage = () => {
  return (
    <div>
      <h3>404 - Not found</h3>
    </div>
  );
};

const App = () => {
  return (
    <div className="App">
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={Routes.Callback} exact component={Callback} />
          <Route path={Routes.Root} exact component={LayoutWrapper(Root)} />
          <Route path={Routes.Webhosting} exact component={LayoutWrapper(Webhosting)} />
          <Route path={Routes.Microsites} exact component={LayoutWrapper(Microsites)} />
          <Route path={Routes.MicrositeDetails} exact component={LayoutWrapper(MicrositeDetails)} />
          <Route path={Routes.AddBSUUser} exact component={LayoutWrapper(AddBSUUser)} />
          <Route path={Routes.HolidayCalendar} exact component={LayoutWrapper(HolidayCalendar)} />
          <Route path={Routes.Logout} exact component={Logout} />
          <Route component={NoMatchPage} />
        </Switch>
      </ConnectedRouter>
    </div>
  );
};

export default App;
