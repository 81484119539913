// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import { FETCH, FETCH_FAILURE, FETCH_SUCCESS } from '../actions/microsites';

import { IMicrositeItem } from '../utils/api.d';

export interface IMicrositesState {
  isLoading: boolean;
  sites: IMicrositeItem[];
  error: Error | null;
}

export const initialState: IMicrositesState = {
  isLoading: false,
  sites: [],
  error: null,
};

export default createReducer<IMicrositesState>(initialState, {
  [FETCH]: (_, state) => ({ ...state, isLoading: true }),
  [FETCH_SUCCESS]: (sites, state) => ({ ...state, sites, isLoading: false }),
  [FETCH_FAILURE]: (error, state) => ({ ...state, error, isLoading: false }),
});
