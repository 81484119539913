// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const START_LOADING = 'APPLICATION/START_LOADING';
export const STOP_LOADING = 'APPLICATION/STOP_LOADING';
export const INIT = 'APPLICATION/INIT';
export const SET_APP_ERROR = 'APPLICATION/SET_ERROR';

export const types = {
  START_LOADING,
  STOP_LOADING,
  INIT,
  SET_APP_ERROR,
};

export const startLoading = createEmptyAction(START_LOADING);
export const stopLoading = createEmptyAction(STOP_LOADING);

export const init = createEmptyAction(INIT);
export const setAppError = createAction(SET_APP_ERROR);

export default {
  startLoading,
  stopLoading,
  init,
  setAppError,
};
