// use 'npm run g:s {name}' - to generate new saga, reducer and action (use --skip if action and/or reducer not needed)
import { put, take, call, fork, select, delay, cancel } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { matchPath } from 'react-router';
import get from 'lodash/get';
import { startLoading, stopLoading, setAppError } from '../actions/application';
import { getApplication } from '../reducers/application';
import { Routes } from '../constants';
import { format, utcToZonedTime } from 'date-fns-tz';

export interface IURLOptions {
  search?: Record<string, string | number>;
  params?: Record<string, string | number>;
}

export const formatInTimeZone = (date, fmt, tz) => format(utcToZonedTime(date, tz), fmt, { timeZone: tz });

export const buildURL = (url: string, options?: IURLOptions) => {
  let search = '';
  let URL = url;
  if (options && options.search) {
    search = `?${Object.keys(options.search)
      .map(key => `${key}=${options && options.search && options.search[key]}`)
      .join('&')}`;
  }
  if (options && options.params) {
    URL = Object.keys(options.params).reduce(
      (u, key) => (u || '').replace(`:${key}`, (options && options.params && String(options.params[key])) || ''),
      url,
    );
  }

  return URL + search;
};

export function* waitCurrentRouteAndDetect(name: string, isRoot?: boolean) {
  const locAction = yield take(LOCATION_CHANGE);

  const path = get(locAction, 'payload.location.pathname');
  return yield path.toLowerCase() === name.toLowerCase() || (isRoot && path === Routes.Root);
}

export function* startDelayedLoading(time: number, immediately?: boolean) {
  if (!immediately) {
    yield delay(time);
  }
  yield put(startLoading());
}

export const getRouteData = (path: string, route: string, isRoot?: boolean) => {
  const match = matchPath(path, route);

  const { params = {} } = match || {};
  const routeName = buildURL(route, { params });
  const isProperPage = path.toLowerCase() === routeName.toLowerCase() || (isRoot && path === Routes.Root);

  return {
    match,
    isProperPage,
  };
};

/*
this helper control a sequence of data loading, you should use it as wrapper for {PAGE} sagas
to ensure that all data is ready you can use application state - isLoading,
because this helper controls START and END of loading data
*/
export function* appLoadingPlaceholder(
  routes: Routes | Routes[],
  childrenSaga: (match: any) => void,
  isRoot?: boolean,
) {
  const locAction = yield take(LOCATION_CHANGE);

  const path = get(locAction, 'payload.location.pathname');

  const { isProperPage, match } = (routes instanceof Array ? routes : [routes])
    .map(r => getRouteData(path, r, isRoot))
    .find(r => r.isProperPage) || { isProperPage: false, match: undefined };

  if (isProperPage) {
    const app = yield select(getApplication);
    const loaderTask = yield fork(startDelayedLoading, 600, app.isFirstLoading);
    try {
      // TODO: check if we need it in Admin app
      // const session = yield select(getSession);
      // if (!session.token && !app.error) {
      //   yield take(SET_SESSION);
      //   yield put(init());
      // }
      yield call(childrenSaga, match);
    } catch (err) {
      console.error('appLoadingPlaceholder', err);
    } finally {
      yield cancel(loaderTask);
      yield put(stopLoading());
    }
  }
}

export const log = (...args: any[]) => console.log(...args);
export const logError = (...args: any[]) => console.error(...args);

export function* callApi(fn: any, ...args: any[]) {
  try {
    yield startDelayedLoading(600);
    return yield call(fn, ...args);
  } catch (err) {
    yield put(setAppError(err));
    throw err;
  } finally {
    yield put(stopLoading());
  }
}

export class HTTPError extends Error {
  status: number;
  constructor(status: number, message: string) {
    super(message);
    this.name = 'HTTPError';
    this.status = status;
  }
}

export const parseQueryParams = (url: string): Record<string, string> => {
  const [_, qParams] = url.split('?');
  const params = (qParams || '').split('&').reduce((result, pair) => {
    const [key, value] = pair.split('=');
    result[key] = value;
    return result;
  }, {});

  return params;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepStateUpdate = (updateKey: string) => (action: Record<string, any>, state: Record<string, any>) => {
  return {
    ...state,
    [updateKey]: {
      ...state[updateKey],
      ...action,
    },
  };
};

export const redirectTo = (url: string, options?: IURLOptions) => push(buildURL(url, options));
