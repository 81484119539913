import React from 'react';
import './styles.scss';
import { Container, Row } from 'reactstrap';
import MicrositesTable from '../../components/MicrositesTable';
import { useHistory } from 'react-router-dom';

const Microsites = () => {
  const history = useHistory();
  return (
    <Container fluid className={'page-container'}>
      <Row className={'sites-pane'}>
        <MicrositesTable historyPush={history.push} />
      </Row>
    </Container>
  );
};

export default Microsites;
