import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import auth from './auth';
// inject import
import application from './application';
import user from './user';
import session from './session';
import locationHistory from './locationHistory';
import navigation from './navigation';
import subscribers from './subscribers';
import alertHistory from './alertHistory';
import microsites from './microsites';
import micrositeDetails from './micrositeDetails';
import bsuuser from './bsuuser';
import holidayCalendar from './holidayCalendar';

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    // inject usage
    application,
    auth,
    user,
    session,
    locationHistory,
    microsites,
    navigation,
    subscribers,
    alertHistory,
    micrositeDetails,
    bsuuser,
    holidayCalendar,
  });
