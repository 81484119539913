let env = {};
function getEnv() {
  const request = new XMLHttpRequest();
  try {
    request.open('GET', '/env', false);
    request.send();
    if (request.status === 200) {
      env = JSON.parse(atob(request.response));
    } else {
      throw new Error('Env file fetch status ' + request.status);
    }
  } catch (error) {
    throw error;
  }
}
getEnv();

export const {
  REACT_APP_ENV,
  REACT_APP_N3A_AUTH_URI,
  REACT_APP_N3A_SITE_ID,
  REACT_APP_AWS_USERPOOLBASEURI,
  REACT_APP_CLIENT_ID,
  REACT_APP_CALLBACK_URI,
  REACT_APP_DASHBOARD_API_BASE_URL,
  REACT_APP_SESSION_API_BASE_URL,
  REACT_APP_S3_PUBLIC_RESOURCES,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USERPOOL,
  REACT_APP_AWS_TOKENSCOPES,
  REACT_APP_BASE_URL,
  REACT_APP_SEGMENT_API_KEY,
  REACT_APP_N3A_URL,
  REACT_APP_KEEP_ALIVE_URL,
  REACT_APP_DELETE_SESSION,
  REACT_APP_DISABLE_AUTH,
  REACT_APP_API_BASE_URL,
  REACT_APP_NAVBAR_STATIC_URL,
} = env as any;

export const SIGNIN_URL = `${REACT_APP_AWS_USERPOOLBASEURI}/authorize?identity_provider=N3A&response_type=token&client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_BASE_URL}${REACT_APP_CALLBACK_URI}`;
export const SIGNOUT_URL = `${REACT_APP_BASE_URL}/logout`;
export const PASSWORD_CHANGE_URL = `${REACT_APP_N3A_AUTH_URI}/Register/ChangePassword?siteId=${REACT_APP_N3A_SITE_ID}`;
export const QUESTION_CHANGE_URL = `${REACT_APP_N3A_AUTH_URI}/Register/EditQuestionAndAnswer?siteId=${REACT_APP_N3A_SITE_ID}`;

export const APP_ID = '583de900-07b6-41d9-b415-9032748fb663';
