import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { IUser } from '../reducers/user';
import { INavItem } from '../reducers/navigation';
import { Routes } from '../constants';

interface IHandlers {
  [key: string]: (navElementId: string) => void;
}

interface INavElement {
  id: string;
  title: string;
  route?: string;
  subNavElements?: Array<INavElement>;
  openInNewTab?: boolean;
  icon?: string;
  hasDivider?: boolean;
}

const userProfileElements = [
  {
    id: 'change-pass',
    title: 'Change Password',
  },
  {
    id: 'change-security',
    title: 'Change Security Questions',
  },
  {
    id: 'logout',
    title: 'Logout',
  },
];

const userProfileNavElements: INavElement = {
  id: 'user-profile',
  title: 'User Profile',
  icon: 'User',
  hasDivider: false,
  subNavElements: userProfileElements,
};

export const makeNavbarHandlers = (handlers: IHandlers) => (navElementId: string) => {
  let trigger = navElementId;
  if (!['change-pass', 'logout', 'change-security'].includes(navElementId)) {
    trigger = 'redirectTo';
  }
  if (handlers.hasOwnProperty(trigger)) {
    handlers[trigger](navElementId);
  }
};

export const changeURL = (navItem?: INavItem) => {
  if (navItem) {
    window.postMessage({ action: 'wc:intrado:navigation', data: navItem }, '*');
  }
};

export const createNavBarHandlers = (dispatch: Dispatch, menu: INavItem[]) =>
  makeNavbarHandlers({
    redirectTo: (nav: string) => {
      const item =
        menu.find(m => m.nav === nav) ||
        menu
          .map(m => {
            if (m.subNavElements) {
              return m.subNavElements.find(sub => sub.nav === nav);
            } else return undefined;
          })
          .find(el => el && el.nav === nav);
      changeURL(item);
      dispatch(push(item ? item.routes[0] : ''));
    },
    logout: () => dispatch(push(Routes.Logout)),
    // 'change-pass': () => window.location.assign(PASSWORD_CHANGE_URL),
    // 'change-security': () => window.location.assign(QUESTION_CHANGE_URL),
  });

type handleCallbacksFunc = (navElementId: string) => void;

export interface NavBarConfig {
  handleCallbacks: handleCallbacksFunc;
  selectedNavElementId?: string;
  user: IUser;
  navElements: INavElement[];
}

export default ({ handleCallbacks, user, navElements, selectedNavElementId = 'root' }: NavBarConfig): any => ({
  navElements,
  userProfileNavElements,
  selectedNavElementId,
  navigationChangeHandler: handleCallbacks,
  accountNavElement: { id: 'user', title: '' },
  userProfileChangeHandler: handleCallbacks,
  accountChangeHandler: handleCallbacks,
  user,
});
