// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH_ALERT_HISTORY,
  FETCH_ALERT_HISTORY_FAILURE,
  FETCH_ALERT_HISTORY_SUCCESS,
  ALERT_HISTORY_SET_MODAL,
} from '../actions/alertHistory';
import { IAlertHistory } from '../utils/api.d';

export interface IAlertHistoryState {
  isLoading: boolean;
  error?: Error;
  alertHistory: IAlertHistory | null;
  isModalOpen: boolean;
}

interface IState {
  alertHistory: IAlertHistoryState;
}

export const getAlertHistory = (state: { alertHistory: IAlertHistoryState }) => state.alertHistory;

export const initialState: IAlertHistoryState = {
  isLoading: false,
  error: undefined,
  alertHistory: null,
  isModalOpen: false,
};

export default createReducer<IAlertHistoryState>(initialState, {
  [FETCH_ALERT_HISTORY]: () => ({ isLoading: true }),
  [FETCH_ALERT_HISTORY_FAILURE]: error => ({ ...initialState, error, isLoading: false }),
  [FETCH_ALERT_HISTORY_SUCCESS]: alertHistory => ({ alertHistory, isLoading: false }),
  [ALERT_HISTORY_SET_MODAL]: isOpen => ({ isModalOpen: isOpen }),
});
