// use 'npm run g:s {name}' - to generate new saga, reducer and action (use --skip if action and/or reducer not needed)
import { put, take, call } from 'redux-saga/effects';
import { FETCH_HOLIDAY_CALENDAR_SUCCESS, FETCH_HOLIDAY_CALENDAR_FAILURE, fetch } from '../actions/holidayCalendar';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';

export function* fetcher() {
  yield put(fetch());
  return yield take([FETCH_HOLIDAY_CALENDAR_SUCCESS, FETCH_HOLIDAY_CALENDAR_FAILURE]);
}

export default function* holidayCalendar() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.HolidayCalendar, fetcher);
  }
}
