// Polyfills to support IE11
import '@babel/polyfill';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/features/promise';
import 'date-time-format-timezone';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/font-awesome/css/all.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ScriptTag from 'react-script-tag';

import App from './App';
import store from './store';
import './App.scss';
import { REACT_APP_NAVBAR_STATIC_URL } from './env';

const rootElement = document.getElementById('unison-dashboard-admin-app') as HTMLElement;

const render = (Application: React.FC): void => {
  ReactDOM.render(
    <Provider store={store}>
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
      <link rel="stylesheet" href={`${REACT_APP_NAVBAR_STATIC_URL}/styles.css`} />
      <ScriptTag src={`${REACT_APP_NAVBAR_STATIC_URL}/runtime.js`} />
      <ScriptTag src={`${REACT_APP_NAVBAR_STATIC_URL}/polyfills.js`} />
      <ScriptTag src={`${REACT_APP_NAVBAR_STATIC_URL}/main.js`} />
      <Application />
    </Provider>,
    rootElement,
  );
};

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  render(App);
}

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
