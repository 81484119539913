// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH = 'SUBSCRIBER/FETCH';
export const FETCH_SUCCESS = 'SUBSCRIBER/FETCH_SUCCESS';
export const FETCH_FAILURE = 'SUBSCRIBER/FETCH_FAILURE';
export const REMOVE_FROM_SUPPRESSED = 'REMOVE_FROM_SUPPRESSED';
export const REMOVE_FROM_SUPPRESSED_SUCCESS = 'REMOVE_FROM_SUPPRESSED/SUCCESS';
export const REMOVE_FROM_SUPPRESSED_FAILURE = 'REMOVE_FROM_SUPPRESSED/FAILURE';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  REMOVE_FROM_SUPPRESSED,
  REMOVE_FROM_SUPPRESSED_SUCCESS,
  REMOVE_FROM_SUPPRESSED_FAILURE,
};

export const fetch = createAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);
export const removeFromSuppressed = createAction(REMOVE_FROM_SUPPRESSED);
export const removeFromSuppressedSuccess = createAction(REMOVE_FROM_SUPPRESSED_SUCCESS);
export const removeFromSuppressedFailure = createAction<Error>(REMOVE_FROM_SUPPRESSED_FAILURE);

export default {
  fetch,
  fetchSuccess,
  fetchFailure,
  removeFromSuppressed,
  removeFromSuppressedSuccess,
  removeFromSuppressedFailure,
};
